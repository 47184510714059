import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components';
import { fonts, ProductDescription, ProductPrice, InactiveProductPrice, breakpointsDown, colors } from '../styling/constants';

import './productPreview.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from './ContextCreator';
import { getPrice, getFormattedPrice } from '../utilities/currencies';

const ProductImage = styled(Img)`
  border: 10px solid white;
  margin-bottom: 1rem;
  width: 100% !important;
  height: 245px !important;
  object-fit: cover !important;
  box-sizing: border-box;
  max-width: 400px;
  opacity: ${(props) => (props.outOfStock ? 0.7 : 1)};

  @media ${breakpointsDown.tablet} {
    height: 190px !important;
  }
`

const OutOfStock = styled.h5`
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 100;
  margin: 0;
  padding: 0;
  color: ${colors.gold};
`

const LinkWrapper = styled(Link)`
  width: 100%;
  position: relative;
`

const ProductName = styled.h4`
  margin: 0.8rem 0 0.3rem 0 !important;
`

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :not(:first-child) {
    margin-left: 1rem;
  }
`

const Discount = styled.div`
  background-color: ${colors.darkGray};
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  color: ${colors.lightGray};
`

const ProductPreview = ({ product, locale }) => {
  const intl = useIntl()
  const { data: contextData } = React.useContext(StoreContext);

  return (
  <div className="product-preview">
    <LinkWrapper to={`${intl.locale !== 'sv' ? '/' + intl.locale : ''}/products/${product.category.title.title.toLowerCase()}/${product.slug}`}>
      { product.outOfStock && <OutOfStock><FormattedMessage id="out-of-stock" /></OutOfStock>}
      <ProductImage outOfStock={product.outOfStock} className="product-image" fixed={product.image[0].fixed} />
      <ProductDescription>{ product.productDescription.productDescription }</ProductDescription>
      <ProductName>{ product.productName.productName }</ProductName>
      <PriceWrapper>
        {product.discount && product.discount != 0 ? (
          <InactiveProductPrice>{ getFormattedPrice(getPrice(product.fullPrice, intl.locale, contextData.currency), intl.locale, contextData.currency) }</InactiveProductPrice>  
        ) : null}
        <ProductPrice>{ getFormattedPrice(getPrice(product.newPrice, intl.locale, contextData.currency), intl.locale, contextData.currency) }</ProductPrice>
        {product.discount && product.discount != 0 ? (
          <Discount>{ product.discount * 100}%</Discount>  
        ) : null}
      </PriceWrapper>
    </LinkWrapper>
  </div>
  )
}

export default ProductPreview
