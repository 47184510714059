import React from 'react'
import styled from 'styled-components'
import { Color, colors, fonts } from '../styling/constants'
import { Link } from 'gatsby'

interface Props {
  link?: string
  text: string
  background: Color
  hoverBackground?: Color
  slide?: boolean
}

interface WrapperProps {
  background: Color
  hoverBackground: Color
  slide: boolean
}

const ButtonWrapper = styled.div<WrapperProps>`
  padding: 1.3rem 2rem;
  margin: 0;
  background-color: ${(props: WrapperProps) => colors[props.background]};
  color: ${colors.lightGray};
  min-width: 14rem;
  font-family: ${fonts.apercu};

  transition: 0.25s;

  &:hover,
  &:focus {
    color: ${colors.darkGray};
    ${(props: WrapperProps) =>
      props.slide
        ? `box-shadow: inset 18rem 0 0 0 ${colors[props.hoverBackground]};`
        : `background-color: ${colors[props.hoverBackground]}`}
  }
`

const LinkText = styled.div`
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 2px;
`

const LargeButton: React.FC<Props> = ({
  link,
  text,
  background,
  hoverBackground = 'lightGray',
  slide = true,
}) => {
  return (
    <>
      { link ? (
        <Link to={link}>
          <ButtonWrapper
            background={background}
            hoverBackground={hoverBackground}
            slide={slide}
          >
            <LinkText>{text}</LinkText>
          </ButtonWrapper>
        </Link>
      ) : (
        <ButtonWrapper
          background={background}
          hoverBackground={hoverBackground}
          slide={slide}
        >
          <LinkText>{text}</LinkText>
        </ButtonWrapper>
      )}
    </>
  )
}

export default LargeButton
