import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { colors, spacings, breakpointsDown } from '../../styling/constants'

const HeroStyling = `
  width: 100%;
  min-height: 500px;
  margin-top: ${spacings.vertical};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12rem ${spacings.horizontal};
  box-sizing: border-box;

  @media ${breakpointsDown.tablet} {
    min-height: 300px;
    padding: 6rem ${spacings.horizontalMobile};
  }
`

interface GatsbyHeroImageProps {
  large: boolean
}

const GatsbyHeroImage = styled(BackgroundImage)<GatsbyHeroImageProps>`
  ${HeroStyling}
  height: ${(props) => (props.large ? '55rem' : 'auto')} !important;
  &:after {
    background-position: center;
  }
  &:before {
    background-position: center;
  }

  @media ${breakpointsDown.tablet} {
    height: ${(props) => (props.large ? '35rem' : 'auto')} !important;
  }
`

interface HeroImageProps {
  imageUrl: string
}

const HeroImage = styled.div<HeroImageProps>`
  ${HeroStyling}
  background: url(${(p: HeroImageProps) => p.imageUrl}) no-repeat center center;
  background-size: cover;
`

const TextWrapper = styled.div`
  background-color: ${colors.lightGray};
  width: 100%;
  padding: 4rem 2rem 2rem 2rem;
  box-sizing: border-box;
  text-align: center;
  color: ${colors.darkGray};
`

interface Props {
  gatsbyImage?: GatsbyTypes.GatsbyImageSharpFluidFragment
  image?: string
  title?: string
  body?: string
  large?: boolean
}

const ImageHeadline: React.FC<Props> = ({
  gatsbyImage,
  image,
  title,
  body,
  large = false,
}) => (
  <>
    {gatsbyImage && (
      <GatsbyHeroImage
        Tag="div"
        style={{ backgroundPosition: '' }}
        fluid={gatsbyImage}
        large={large}
      >
        {title && (
          <TextWrapper>
            {title && <h3>{title}</h3>}
            {body && <p>{body}</p>}
          </TextWrapper>
        )}
      </GatsbyHeroImage>
    )}
    {image && (
      <HeroImage imageUrl={image}>
        <TextWrapper>
          {title && <h3>{title}</h3>}
          {body && <p>{body}</p>}
        </TextWrapper>
      </HeroImage>
    )}
  </>
)

export default ImageHeadline
